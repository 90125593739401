<template>
  <h2 v-if="content?.name?.includes('[community]')" class="elevate-title-3 mx-4 mb-6 c-grey-30 lg:mx-10 lg:mb-8">
    Community
  </h2>
  <div ref="elementRef" v-style:bg="backgroundColor" class="relative grid" @click="handlePromotionClick">
    <div class="relative grid-area-stack">
      <base-picture
        v-if="responsiveMedia.images"
        v-bind="responsiveMedia.images"
        :lazy
        fit="cover"
        class="full"
      />
      <base-video
        v-else-if="responsiveMedia.video"
        v-bind="video"
        ref="videoRef"
        :autoplay="!lazy"
        :controls="false"
        loop
        muted
        class="full cover"
      />
      <div class="absolute-0" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, #000000 100%);" />
    </div>
    <div class="relative mt-a w-full px-4 pb-10 grid-area-stack lg:px-10">
      <h2 v-if="title" class="elevate-title-1 c-white" data-test-id="cms-banner-title">
        {{ title }}
      </h2>
      <p v-if="subtitle" class="elevate-body-2 mt-4 c-white" data-test-id="cms-banner-text">
        {{ subtitle }}
      </p>
      <div
        v-if="linkType !== 'No-CTA' && targets.length"
        class="gap-4 wrap i-flex not-first:mt-6"
        :class="equalTargetSizeClass"
      >
        <base-link
          v-for="({ text, url }, i) in targets"
          :key="i"
          :to="url"
          class="elevate-body-2 rounded-full px-6 py-3 duration lg:py-4"
          :class="i === 0 ? 'bg-transparent text-white b-1 b-white @hactive:bg-white @hactive:text-black' : 'b b-white bg-transparent c-white @hactive:bg-black/16'"
        >
          {{ text }}
        </base-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type { BannerImageContent } from '#types/components/cms/banner-image'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'

const { content } = defineProps<{
  content: BannerImageContent
}>()

const {
  backgroundColor,
  equalTargetSize,
  linkType,
  media,
  promotionTracking,
  subtitle,
  targets = [],
  title
} = content

const { getMedia, getVideo } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const responsiveMedia = getMedia(media)
const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

const equalTargetSizeClass = targets.length > 1 && [
  getValueForBreakpoint('sm', equalTargetSize) && '<md:grid <md:auto-rows-fr <md:items-stretch',
  getValueForBreakpoint('md', equalTargetSize) && 'md:grid md:auto-rows-fr md:items-stretch',
  getValueForBreakpoint('lg', equalTargetSize) && 'lg:grid lg:auto-rows-fr lg:items-stretch'
].filter(Boolean).join(' ')

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
